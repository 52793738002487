import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IJsonObject } from 'dcerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LmsService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);

  path = '/lms';

  constructor() {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * Check if the LMS related to a course can access moodle
   *
   * @param courseCode Code of the course related to the LMS to check
   * @returns Whether the LMS has access
   */
  checkLmsAccess(courseCode: string): Observable<boolean> {
    return this.http.get<IJsonObject>(`${this.path}/${courseCode}/has-access`).pipe(map((response) => !!response));
  }
}
