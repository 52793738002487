import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { environment } from '../environments/environment';
import { CustomerModuleKey } from '../models/customer';
import { CourseService } from './course.service';
import { CustomerService } from './customer.service';

export const courseDetailGuard: CanActivateFn = async (route) => {
  const router = inject(Router);
  const courseService = inject(CourseService);
  const customerService = inject(CustomerService);

  try {
    if (localStorage.getItem('test_mode') && environment.name !== 'prod') {
      return true;
    }

    const courseId = route.paramMap.get('id');
    const course = await lastValueFrom(courseService.getCourseById(Number(courseId)));

    if (course.isActive) {
      if (customerService.isCustomerModuleEnabled(CustomerModuleKey.ONLYFREECOURSESWEB)) {
        return course.isFree();
      }

      return true;
    } else {
      return await lastValueFrom(courseService.isEnrolled(course.code as string));
    }
  } catch {
    return router.parseUrl('overview');
  }
};
