import { FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { Inject, Injectable } from '@angular/core';

import { ApiService } from '../services/api.service';
import { SubdomainConfigService } from '../services/subdomain-config.service';

@Injectable({ providedIn: 'root' })
export class CustomFacebookLoginProvider extends FacebookLoginProvider {
  constructor(
    apiService: ApiService,
    subdomainConfService: SubdomainConfigService,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Inject(Object) initOptions?: any
  ) {
    const clientId = subdomainConfService.getConfigForSubdomain(apiService.getApiHostname()).socialLoginConfig.facebook
      .clientId;

    super(clientId, initOptions);
  }
}
