import { inject } from '@angular/core';
import { MatDialog, type MatDialogConfig } from '@angular/material/dialog';
import { type CanActivateFn, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

import {
  RedirectionModalComponent,
  type RedirectionModalData
} from '../app/course/course-detail/redirection-modal/redirection-modal.component';
import { SnackbarStatus } from '../definitions/snackbar-status.enum';
import { CourseService } from './course.service';
import { CustomSnackbarService } from './custom-snackbar.service';
import { LmsService } from './lms_service';

/**
 * Whether the user can access certain sections of the course or not
 * @param route: You need to supply a section from the CourseSectionAccess value via the parameter accessTo
 * in the data property of the routing module. It specifies which section from the Course component
 * you are trying to access to.
 */
export const courseAccessGuard: CanActivateFn = async (route) => {
  const router = inject(Router);
  const courseService = inject(CourseService);
  const snackbarService = inject(CustomSnackbarService);
  const translate = inject(TranslateService);
  const matDialog = inject(MatDialog);
  const lmsService = inject(LmsService);

  const accessToMoodle = route.url.toString().includes('moodle');

  try {
    const courseCode = route.queryParamMap.get('courseCode') as string;
    const value = await lastValueFrom(courseService.isEnrolled(courseCode));
    if (!value && accessToMoodle) {
      snackbarService.present(translate.instant('moodleGuard.subNotValid'), SnackbarStatus.Error);

      return router.parseUrl(`course/${route.paramMap.get('id')}`);
    }

    const lmsHasAccess = await lastValueFrom(lmsService.checkLmsAccess(courseCode));
    if (!lmsHasAccess) {
      snackbarService.present(translate.instant('moodleGuard.notAccess'), SnackbarStatus.Info);

      return false;
    }

    if (value && accessToMoodle) {
      if (route.queryParamMap.get('isCourseLegacy') === 'true') {
        return true;
      }

      const course = await lastValueFrom(courseService.getCourseById(parseInt(route.paramMap.get('id') as string)));
      if (course.isFromBackoffice()) {
        const dialogParams: MatDialogConfig<RedirectionModalData> = {
          width: '36.5rem',
          height: 'auto',
          data: { course: course }
        };
        matDialog.open(RedirectionModalComponent, dialogParams);

        return false;
      }

      return true;
    }

    return true;
  } catch {
    return router.parseUrl('overview');
  }
};
